import { React, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Masonry from "react-masonry-css";
import { brandConfig } from "./brand-config";
import "./Brand.scss";
import Vimeo from "@u-wave/react-vimeo";

const SneakPeak = (props) => {
  useEffect(() => {}, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const getItemsArray = () => {
    return brandConfig.map((config) => (
      <div className="item" key={config.id}>
        <h2>{config.title}</h2>
        <p>{config.content}</p>
        {config.img && <img src={config.img} alt={config.title} />}
      </div>
    ));
  };

  const breakpointColumnsObj = {
    default: 3,
    1250: 2,
    990: 1,
  };

  return (
    <div id="page-wrapper">
      <Sidebar />
      
      <div className="sneak-content">

      <div className="video-wrapper">
        <div className="big-video-element">
        <Vimeo
              video="702517576"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              className="vimeo-player"
            ></Vimeo>
        </div>
      </div>


      <div className="video-wrapper">
        <div className="two-images">  
        <div className="video-element">
            <Vimeo
            video="701599971"
            width={"100%"}
            autoplay
            loop
            background
            responsive
            className="vimeo-player"
            ></Vimeo>
          </div>
          <img src="/assets/brandSign.jpg" alt="spaghetti" />
          
        </div>
      </div>

     <div className="big-img">
      <img src="/assets/brandWindow.jpg" alt="spaghetti" />
     </div>


     <div className="video-wrapper">
        <div className="two-images">  
        <div className="video-element">
            <Vimeo
            video="701598824"
            width={"100%"}
            autoplay
            loop
            background
            responsive
            className="vimeo-player"
            ></Vimeo>
          </div>
          <img src="/assets/brandResponsive.jpg" alt="spaghetti" />
          
        </div>
      </div>



      <div className="project-information-container">
      
      <div className="item-desc">
        <h2>{brandConfig[0].title}</h2>
        <p>{brandConfig[0].content}</p>
        <br/>
        <p>You're already here :)</p>
        
      </div>

      <div className="item-info">
        <h2>{brandConfig[1].title}</h2>
        <p>{brandConfig[1].content}</p>
      </div>

      </div>
      

        
      </div>
    </div>
  );
};

export default SneakPeak;
