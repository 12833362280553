import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Art from "./pages/Art/Art";
import Brand from "./pages/Brand/Brand";
import Apart from "./pages/Apart/Apart";
import Gertie from "./pages/Gertie/Gertie";
import Sneakpeek from "./pages/Sneakpeek/Sneakpeek";
import Merch from "./pages/Merch/Merch";
import Magazine from "./pages/Magazine/Magazine";
import Teaser from "./pages/Teaser/Teaser";
import Podcast from "./pages/Podcast/Podcast";
import Brix from "./pages/Brix/Brix";
import Shortfilm from "./pages/Shortfilm/Shortfilm";
import RWUKampagne from "./pages/rwuKampagne/RWUKampagne";

import Linse from "./pages/Linse/Linse";
import Polyful from "./pages/Polyful/Polyful";
import Leto from "./pages/Leto/Leto";
import Exploration from "./pages/Exploration/Exploration";

const Home = lazy(() => import("./pages/home/Home"));
const About = lazy(() => import("./pages/about/About"));
const AboutCopy = lazy(() => import("./pages/about/AboutCopy"));

const App = (props) => {


  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<AboutCopy />} />
          <Route exact path="/sneakpeek" element={<Sneakpeek />} />
          <Route exact path="/campus-collab" element={<Merch />} />
          <Route exact path="/code-magazine" element={<Magazine />} />
          
          <Route exact path="/rwu-kampagne" element={<RWUKampagne />} />
          <Route exact path="/rwu-and-you" element={<Teaser />} />
          <Route exact path="/podcast" element={<Podcast />} />
          <Route exact path="/brix" element={<Brix />} />
          <Route exact path="/know-your-clothes" element={<Shortfilm />} />
          <Route exact path="/art-gallery" element={<Art />} />
          <Route exact path="/branding" element={<Brand />} />
          <Route exact path="/apart" element={<Apart />} />
          <Route exact path="/gertie" element={<Gertie />} />

          <Route exact path="/leto" element={<Leto />} />
          <Route exact path="/linse" element={<Linse />} />
          <Route exact path="/polyful" element={<Polyful />} />
          <Route exact path="/exploration" element={<Exploration />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
