import { React, useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Masonry from "react-masonry-css";
import { polyfulConfig } from "./polyful-config";
import "./Polyful.scss";
import Vimeo from "@u-wave/react-vimeo";
import { Link } from "react-router-dom";

const Polyful = (props) => {
  const [productsLoaded, setProductsLoaded] = useState(false);
  const [logoSpinningLoaded, setLogoSpinningLoaded] = useState(false);
  const [nameLoaded, setNameLoaded] = useState(false);
  const [colorsLoaded, setColorsLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  const handleLogoSpinningLoaded = () => {
    setLogoSpinningLoaded(true);
  }

  const handleProductsLoaded = () => {
    setProductsLoaded(true);
  }

  const handleNameLoaded = () => {
    setNameLoaded(true);
  }

  const handleColorsLoaded = () => {
    setColorsLoaded(true);
  }






  return (
    <div id="page-wrapper">
      <Sidebar />

      <div className="polyful-content">
        <div className="big-img">
          <img src="/assets/polyful/1_polyful_hero.jpg" alt="spaghetti" />
        </div>

        <div className="video-wrapper">
          <div className="video-element">
            {!productsLoaded && <div className="skeleton"></div>}
            <Vimeo
              video="866810978"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleProductsLoaded}
              className="vimeo-player"
            ></Vimeo>
          </div>

          <div className="video-element">
          {!logoSpinningLoaded && <div className="skeleton"></div>}
            <Vimeo
              video="866810998"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleLogoSpinningLoaded}
              className="vimeo-player"
            ></Vimeo>
          </div>
        </div>

        <div className="big-img">
          <img src="/assets/polyful/3_label.jpg" alt="spaghetti" />
        </div>

        <div className="two-images">
          <img src="/assets/polyful/4_1_website_mobile.jpg" alt="spaghetti" />
          <img src="/assets/polyful/4_2_businesscard.jpg" alt="spaghetti" />
        </div>

        <div className="video-wrapper">
          <div className="big-video-element">
          {!nameLoaded && <div className="skeleton"></div>}
            <Vimeo
              video="867500547"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleNameLoaded}
              className="vimeo-player"
            ></Vimeo>
          </div>
        </div>

        <div className="video-wrapper">
          <div className="two-images">
            <img src="/assets/polyful/6_1_typo3.jpg" alt="spaghetti" />
            <div className="video-element">
            {!colorsLoaded && <div className="skeleton"></div>}
              <Vimeo
                video="866811048"
                width={"100%"}
                autoplay
                loop
                background
                responsive
                onPlay={handleColorsLoaded}
                className="vimeo-player"
              ></Vimeo>
            </div>
          </div>
        </div>

        <div className="big-img">
          <img src="/assets/polyful/7_brochure.jpg" alt="spaghetti" />
        </div>

        <div className="big-img">
          <img src="/assets/polyful/8_website_web.jpg" alt="spaghetti" />
        </div>

        <div className="polyful-project-information-container">
          <div className="item-desc">
            <h2>{polyfulConfig[0].title}</h2>
            <p>{polyfulConfig[0].content}</p>
            <br />
            <a target="_blank" href="https:// www.polyful.de">
              <p className="external-link">Visit the POLYFUL Website</p>
            </a>
          </div>

          <div className="item-info">
            <h2>{polyfulConfig[1].title}</h2>
            <p>{polyfulConfig[1].content}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Polyful;
