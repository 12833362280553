import { React, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Masonry from "react-masonry-css";
import { shortfilmConfig } from "./shortfilm-config";
import "./Shortfilm.scss";
import Vimeo from "@u-wave/react-vimeo";

const Shortfilm = (props) => {
  useEffect(() => {}, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div id="page-wrapper">
      <Sidebar />
      
      <div className="sneak-content">

      <div className="video-wrapper">
        <div className="big-video-element">
        <Vimeo
              video="702353526"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              className="vimeo-player"
            ></Vimeo>
        </div>
      </div>
      

      <div className="video-wrapper">
        <div className="two-images">  
        <div className="video-element">
            <Vimeo
            video="702359901"
            width={"100%"}
            autoplay
            loop
            background
            responsive
            className="vimeo-player"
            ></Vimeo>
          </div>
          <img src="/assets/leuteStillBTS.jpg" alt="spaghetti" />
          
        </div>
      </div>

      
     <div className="big-img">
      <img src="/assets/leuteStill01.jpg" alt="spaghetti" />
     </div>


     <div className="video-wrapper">
        <div className="two-images">  
        
          <img src="/assets/leuteStill02.jpg" alt="spaghetti" />
          <div className="video-element">
            <Vimeo
            video="702359901"
            width={"100%"}
            autoplay
            loop
            background
            responsive
            className="vimeo-player"
            ></Vimeo>
          </div>
        </div>
      </div>


      <div className="project-information-container">
      
      <div className="item-desc">
        <h2>{shortfilmConfig[0].title}</h2>
        <p>{shortfilmConfig[0].content}</p>
        <br/>
        <a target="_blank" href="https://www.youtube.com/watch?v=5H3aunpZhnc">
        <p className="external-link">Check out this Shortfilm</p>
        </a>
      </div>

      <div className="item-info">
        <h2>{shortfilmConfig[1].title}</h2>
        <p>{shortfilmConfig[1].content}</p>
      </div>

      </div>

        
      </div>
    </div>
  );
};

export default Shortfilm;
