export const letoConfig = [
    {
        id: 1,
        title: "Project Description",
        content: "LETO - derived from the word 'learning tool' - is a prototype designed for tablets. Students have the opportunity to work on the content of their respective grade level at their own pace. They are guided through the school year by a playful and bold design language and find help in discovering, researching and mastering challenging learning chapters.\n\n" +
                "LETO encourages initiative in homework and certificates. But LETO also provides teachers with an overview of the entire class, so they can support children who need a little extra help. The teacher can also open class cups, where neighboring classes can be challenged and team spirit can be strengthened."

    },

    {
        id: 2,
        title: "Detailed Information",
        content: "Creator \n" + "-- \n" +
        "Niklas Thielen \n \n" +
        
        "Software Usage \n" + "-- \n" +
        "Adobe Illustrator: Visual Concept, Asset Creation \n \n" +
        "Adobe Xd: Prototype \n \n" +
        "Adobe After Effects: Motion Design \n \n" +
        "Adobe Photoshop: Usage Examples"
        


    },
]