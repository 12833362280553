import { React, useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Masonry from "react-masonry-css";
import { sneakConfig } from "./sneakpeek-config";
import "./Sneakpeek.scss";
import Vimeo from "@u-wave/react-vimeo";

const SneakPeak = (props) => {
  useEffect(() => {}, []);


  const [sneakpeekHeroLoaded, setSneakpeekHeroLoaded] = useState(false);
  const [sneakpeekTurntableLoaded, setSneakpeekTurntableLoaded] = useState(false);
  const [sneakpeekMRLoaded, setSneakpeekMRLoaded] = useState(false);
  const [sneakpeekCartLoaded, setSneakpeekCartLoaded] = useState(false);
  const [sneakpeekPGLoaded, setSneakpeekPGLoaded] = useState(false);
  const [sneakpeekSelectionLoaded, setSneakpeekSelectionLoaded] = useState(false);


  const handleSneakpeekHero = () => {
    setSneakpeekHeroLoaded(true);
  }

  const handleSneakpeekTurntable = () => {
    setSneakpeekTurntableLoaded(true);
  }

  const handleSneakpeekMR = () => {
    setSneakpeekMRLoaded(true);
  }

  const handleSneakpeekCart = () => {
    setSneakpeekCartLoaded(true);
  }

  const handleSneakpeekPG = () => {
    setSneakpeekPGLoaded(true);
  }

  const handleSneakpeekSelection = () => {
    setSneakpeekSelectionLoaded(true);
  }



  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div id="page-wrapper">
      <Sidebar />
      
      <div className="sneak-content">

      <div className="video-wrapper">
        <div className="big-video-element">
        {!sneakpeekHeroLoaded && <div className="skeleton"></div>}
        <Vimeo
              video="702558717"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleSneakpeekHero}
              quality="1080p"
              className="vimeo-player"
            ></Vimeo>
        </div>
      </div>


      <div className="video-wrapper">
        <div className="two-images">  
          <img src="/assets/sneakpeekStill03.jpg" alt="spaghetti" />
            <div className="video-element">
            {!sneakpeekCartLoaded && <div className="skeleton"></div>}
              <Vimeo
              video="702545054"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleSneakpeekCart}
              quality="1080p"
              className="vimeo-player"
              ></Vimeo>
            </div>
          </div>
      </div>

    
     


      <div className="video-wrapper">
        <div className="big-video-element">
        {!sneakpeekTurntableLoaded && <div className="skeleton"></div>}
        <Vimeo
              video="702407886"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleSneakpeekTurntable}
              quality="1080p"
              className="vimeo-player"
            ></Vimeo>
        </div>
      </div>



        <div className="video-wrapper">
          
          <div className="video-element">
          {!sneakpeekPGLoaded && <div className="skeleton"></div>}
              <Vimeo
              video="702519074"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleSneakpeekPG}
              quality="1080p"
              className="vimeo-player"
              ></Vimeo>
            </div>
        
            <div className="video-element">
            {!sneakpeekSelectionLoaded && <div className="skeleton"></div>}
              <Vimeo
              video="702410661"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleSneakpeekSelection}
              quality="1080p"
              className="vimeo-player"
              ></Vimeo>
            </div>  
        </div>


        <div className="video-wrapper">
        <div className="big-video-element">
        {!sneakpeekMRLoaded && <div className="skeleton"></div>}
        <Vimeo
              video="702557021"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleSneakpeekMR}
              quality="1080p"
              className="vimeo-player"
            ></Vimeo>
        </div>
      </div>

      <div className="sneakpeek-project-information-container">
      
      <div className="item-desc">
        <h2>{sneakConfig[0].title}</h2>
        <p>{sneakConfig[0].content}</p>
        <br/>
        <a target="_blank" href="https://www.youtube.com/watch?v=GBiprFtJYOw">
        <p className="external-link">Watch the Walkthrough</p>
        </a>
      </div>

      <div className="item-info">
        <h2>{sneakConfig[1].title}</h2>
        <p>{sneakConfig[1].content}</p>
      </div>

      </div>

        
      </div>
    </div>
  );
};

export default SneakPeak;
