export const polyfulConfig = [
    {
        id: 1,
        title: "Project Description",
        content: "polyful makes polyhedra that are easy to build and fun to touch and look at. The polyhedra combine mathematics and beauty. They are made of precision parts, dimensionally stable and available in many colors and sizes. For the newly founded company polyful we created a complete corporate design, a website, product photos and an instruction video on how to assemble the polyhedra."

    },

    {
        id: 2,
        title: "Detailed Information",
        content: "Creator \n" + "-- \n" +
        "Stephan Wedekind \n" +
        "Maxi Schellhaase \n" +
        "Niklas Thielen \n \n" +
        
        "Software Usage \n" + "-- \n" +
        "Adobe Illustrator: Ideation, Visual Concept \n \n" +
        "Adobe InDesign: Editorial Design, Layout Creation \n \n" +
        "Adobe Xd: Website Prototyp \n \n" +
        "Adobe Lightroom: Image Editing \n \n" +
        "Adobe After Effects: Motion Design \n \n" +
        "Adobe Photoshop: Usage Examples"
        


    },
]