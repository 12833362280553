export const rwuKampagneConfig = [
    {
        id: 1,
        title: "Project Description",
        content: "Ein junges Studio das plakative, modulare Designkonzepte erstellt. Das ist das Leitbild von 9INE 7EVEN Designstudio. Im Wahlmodul Grafikdesign habe ich diese Brand Identity konzipiert und an Probearbeiten angewendet. Eine Kunstausstellung bietet sich für so ein Beispiel ideal an, weil sie Aufmerksamkeit erregen soll, man aber trotzdem viel Freiheit in der Gestaltung hat. So wurde ein Baukasten erstellt der in vielen verschiedenen Kombinationen funktioniert."

    },

    {
        id: 2,
        title: "Detailed Information"
    }
    
]