import { React, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Masonry from "react-masonry-css";
import { sneakImagesConfig } from "./merch-config";
import "./Merch.scss";
import Vimeo from "@u-wave/react-vimeo";

const Merch = (props) => {
  useEffect(() => {}, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div id="page-wrapper">
      <Sidebar />
      
      <div className="sneak-content">

     
      <div className="big-img">
      <img src="/assets/platzhalterGross.jpg" alt="spaghetti" />
      </div>

      <div className="two-images">  
          
          <img src="/assets/platzhalterKlein.jpg" alt="spaghetti" />
          <img src="/assets/platzhalterKlein.jpg" alt="spaghetti" />
         
          
        </div>


      <div className="big-img">
      <img src="/assets/platzhalterGross.jpg" alt="spaghetti" />
      </div>
      

        
      </div>
    </div>
  );
};

export default Merch;
