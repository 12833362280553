export const sneakConfig = [
    {
        id: 1,
        title: "Project Description",
        content: "A holographic online sneaker store. A peek of what the future might be like. \n \nSNEAKPEEK is a holographic online sneaker store. It offers the possibility to enter a virtual sneaker store and to interact with the products through 'hand tracking'. The name is a play on words from the words 'sneaker' and 'sneakpeek' and is intended to provide a glimpse into a possible future of online retail. Nine products were developed as part of the project, but in theory it can be expanded.\n \nA product is selected by air-tapping on it and bringing it to you. In 'focus mode,' the sneaker can be held and turned like a real shoe. It can also be scaled by pulling the two hands apart to get a closer look at the texture and color. By liking a product, it is saved in the favorites, which can be accessed through the the menu bar at any time. You can also view the shopping cart, where you can get information about your last orders, e.g. the payment and order status. In the profile you have the possibility to change the size, address and payment method and to switch to other created profiles."

    },

    {
        id: 2,
        title: "Detailed Information",
        content: "Creator:  Niklas Thielen \n \n" +
        "Software Usage \n" + "-- \n" +
        
        "Unity: Software Creation and Programming \n \n" +
        "Visual Studio 2019: Application Deployment to HoloLens Gen 2 \n \n" +
        "Agisoft Metashape: Photogrammetry, Model and Texture Creation \n \n" +
        "Blender: Model and Texture Preparation \n \n" +
        "Adobe Illustrator: Asset Creation and Preparation \n \n" +
        "Adobe InDesign: Documentation and Presentation \n \n" +
        "Adobe Lightroom: RAW Image Editing \n \n" +
        "Adobe After Effects: Motion Design \n \n"
        


    },
]