export const sidebarConfig = [
    {
        id: 1,
        category: "Home",
        image: "/assets/logos/logo_nineseven_alt.svg",
        title: "Home",
        description: null,
        url: "/"
    },{
        id: 2,
        category: "Info",
        image: "/assets/logos/logo_me.svg",
        title: "About Me",
        description: "Hey! Welcome to my portfolio.\n" +
        "-Enjoy hanging around",
        url: "/about"
    },
    /*{
        id: 3,
        category: "Branding",
        image: "/assets/logoBrand.jpg",
        title: "9INE 7EVEN",
        description: "A young studio, that creates modular and bold design concepts",
        url: "/branding"
    },
    {
        id: 7,
        category: "Movie",
        image: "/assets/logoTeaser.jpg",
        title: "RWU&U",
        description: "A Project to push social media presence and motivate to apply",
        url: "/rwu-and-you"
    },
    {
        id: 11,
        category: "Movie",
        image: "/assets/filmLogo.jpg",
        title: "Know Your Clothes",
        description: "A Shortfilm to raise awarness of the clothing industry and ultrafast fashion",
        url: "/know-your-clothes"
    },
    {
        id: 8,
        category: "Podcast",
        image: "/assets/logoPodcast.jpeg",
        title: "Podcast_final",
        description: "A weekly Podcast about the ordinary life with two friends and myself",
        url: "/podcast"
    },*/
    {
        id: 12,
        category: "Branding",
        image: "/assets/logos/logo_polyful.svg",
        title: "Polyful",
        description: "A brand-new visual identity for wholesaler Polyful to launch into the marketplace",
        url: "/polyful"
    },
    {
        id: 13,
        category: "Branding",
        image: "/assets/logos/logo_linse.svg",
        title: "Linse Kulturzentrum",
        description: "Fresh, out-of-the-box identity and online presence for the cinema and restaurant in Weingarten",
        url: "/linse"
    },
    {
        id: 14,
        category: "Branding & UX Design",
        image: "/assets/logos/logo_leto.svg",
        title: "Leto",
        description: "A tablet based learning tool to help children experience learning in a joyful way and in their own speed",
        url: "/leto"
    },
    {
        id: 6,
        category: "Print",
        image: "/assets/logos/logo_magazin.svg",
        title: "CODE Magazine",
        description: "The first ever CODE Magazine showing great projects of the Mediadesign class at the RWU",
        url: "/code-magazine"
    },
    {
        id: 10,
        category: "Mixed Reality",
        image: "/assets/logos/logo_sneakpeek.svg",
        title: "SNEAKPEEK",
        description: "A holographic online Sneaker Store. A Peek of what the Future might be like",
        url: "/sneakpeek"
    },
    {
        id: 10,
        category: "This & That",
        image: "/assets/logos/logo_exploration.svg",
        title: "Exploration",
        description: "Some sideprojects and spare time experiments",
        url: "/exploration"
    },
    /*{
        id: 3,
        category: "Print",
        image: "/assets/logoPoster.jpg",
        title: "Lee Krasner Art Gallery",
        description: "A Poster Concept for the expressionist Lee Krasner",
        url: "/art-gallery"
    },*/
]