import { React, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Masonry from "react-masonry-css";
import { sneakImagesConfig } from "./brix-config";
import "./Brix.scss";
import Vimeo from "@u-wave/react-vimeo";

const Brix = (props) => {
  useEffect(() => {}, []);

  return (
    <div id="page-wrapper">
      <Sidebar />
      
      <div className="sneak-content">

      

        
      </div>
    </div>
  );
};

export default Brix;
