import { React, useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Masonry from "react-masonry-css";
import { explorationConfig } from "./exploration-config";
import "./Exploration.scss";
import Vimeo from "@u-wave/react-vimeo";
import { Link } from "react-router-dom";

const Exploration = (props) => {


  const getItemsArray = () => {
    return explorationConfig.map((config, i) => (
      <div className="item" key={i}>
        <p>{config.entryTitle}</p>
        <h3>{config.title}</h3>
        <p>{config.content}</p>
        {config.img && <img src={config.img} alt={config.title} />}
        {config.video &&  
          <div className="video-element">
              <div className="video-exploration">
                <Vimeo
                video={config.video}
                width={"100%"}
                autoplay
                loop
                background
                responsive
                quality="1080p"
                className="vimeo-player"
                /> 
             </div>
          </div>
        }
        
      </div>
    ));
  };

  const breakpointColumnsObj = {
    default: 3,
    1250: 2,
    990: 1,
  };
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="page-wrapper">
      <Sidebar />

      
      <div className="sneak-content">


       <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {getItemsArray()}
        </Masonry>
       

        
      </div>
    </div>
  );
};

export default Exploration;
