export const podcastConfig = [
    {
        id: 1,
        title: "Project Description",
        content: "A weekly Podcast about the ordinary life with two friends and myself. \n \nIt's spring 2020, Corona is widely spread and everyone is sitting at home. Because we hardly see each other and all parties and concerts are cancelled, we wanted to at least bring simple small talk back to life. That's the genesis of Podcast_final. The podcast that is sometimes more, sometimes less serious. In a solid 120 minutes, political events are classified and children's games are explained. There is philosophical discussion and gossip about celebrities. And there is laughter. A lot of laughter. "

    },

    {
        id: 2,
        title: "Detailed Information",
        content: "Creator \n" + "-- \n" +
        "Stephan Wedekind \n" +
        "Maxi Schellhaase \n" +
        "Niklas Thielen \n \n" +
        
        "Software Usage \n" + "-- \n" +
        "Adobe Audition: Recording and Editing \n \n" +
        "Adobe After Effects: Motion Design \n \n" +
        "Adobe Photoshop: Usage Examples"
        


    },
]