export const explorationConfig = [
    
    {
        id: 20,
        entryTitle: "#Exercise",
        title: "SmoothDeep",
        content: "A branding practice. Pack you bags, the world awaits.",
        img: null,
        video: "877482774",
    },/* {
        id: 19,
        entryTitle: "#021 Coming Soon: Textile Design Project",
        title: "Campus Collab - RWU Merch",
        content: "University Merch in a modern way.",
        img: null,
        video: "868234545",
    }, */
    /*{
        id: 21,
        entryTitle: "#020 Coming Soon: Corporate Design Project",
        title: "Linse Kulturzentrum",
        content: "A brand new identity and online presence for the cinema and restaurant in Weingarten.\n \nThe Linse Kulturzentrum now wants to appeal to a younger audience with a rebrand. With a new logo and a modern website, this should succeed. But also neon signs, menus and everything else that belongs to a new brand identity must not be missing. A project that is happening at this very moment.",
        img: "/assets/blogLinse.jpg",
    },
    {
        id: 18,
        entryTitle: "#019 Entry: Print Media Project",
        title: "C.O.D.E. Magazine",
        content: "Center of Digital Experience. \n \nThe first ever CODE Magazine showing great projects of the Mediadesign class at the RWU.",
        img: "/assets/blogMagazine.jpg",
    },*/
   /*  {
        id: 18,
        entryTitle: "#018 Entry: Image Campagne Project",
        title: "RWU - Anders als du denkst",
        content: "A Social Media and Print Campagne in Collaboration with Studio Sued.",
        img: "/assets/blogkampagne.jpg",
    },
    {
        id: 17,
        entryTitle: "#017 Entry: Android Project",
        title: "Cardy",
        content: "Collect stamps and get free Stuff!",
        img: "/assets/blogCardy.jpg",
    },
    {
        id: 16,
        entryTitle: "#016 Entry: Shortfilm Project",
        title: "RWU&U Teaser-Recap",
        content: "Who is the team behind this project?",
        img: "/assets/blogTeaserRecap.jpg",
    },
    {
        id: 14,
        entryTitle: "#014 Entry: Shortfilm Project",
        title: "RWU&U Teaser",
        content: "A Project to push social media presence and motivate to apply.",
        img: "/assets/blogTeaser.jpg",
    },
    {
        id: 12,
        entryTitle: "#012 Entry: Print Campagne Project",
        title: "Lee Krasner Art Gallery",
        content: "A Poster Concept for the expressionist Lee Krasner.",
        img: null,
        video: "701644700"
    },
    {
        id: 11,
        entryTitle: "#011 Entry: Corporate Identity Project",
        title: "9INE 7EVEN ",
        content: "A young studio that creates striking, modular design concepts.",
        img: null,
        video: "701599971"
    }, 
    {
        id: 7,
        entryTitle: "Explorations and Side projects",
        title: "Instagram Posts: How 2",
        content: "Social Media Content Creation.",
        img: "/assets/blogJourney.jpg",
    },
    {
        id: 5,
        entryTitle: "#005 Entry: Podcast Production Start",
        title: "Podcast_final",
        content: "Your weekly podcast about everything you can imagine.",
        img: "/assets/blogPodcast02.jpg",
    }, */
    
    
]