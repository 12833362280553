export const magazineConfig = [
    {
        id: 1,
        title: "Project Description",
        content: "CODE is the new stage for modern designers. The magazine stands for Center Of Digital Experience. We want to show you how diverse, demanding, challenging, but also fulfilling our projects in the media design program at RWU can be. Before we work out our creative ideas, we first have to think them through. In order not to shorten the important thought processes too quickly, we leave our beloved tools, notebook and mouse, packed on the cover for the time being and form our ideas with our heads at the beginning.\n \nThe magazine is divided into the contents of the different semesters and thus guides prospective students through the modules of the media design course. A modular editorial concept makes it possible to easily add projects and still present them in an individualized way. The cover is divided into two components: The image of the mouse and a sticker, which can be individualized on the cover and is uniquely numbered. The QR-Code takes you to the CODE Magazine website, where you can also view motion design and film projects."

    },

    {
        id: 2,
        title: "Detailed Information",
        content: "Creator \n" + "-- \n" +
        "Stephan Wedekind \n" +
        "Niklas Thielen \n \n" +
        
        "Software Usage \n" + "-- \n" +
        "Adobe InDesign: Editorial Design, Layout Creation \n \n" +
        "Adobe Illustrator: Asset Creation and Preparation \n \n" +
        "Adobe Lightroom: Cover Image Editing \n \n" +
        "Adobe After Effects: Motion Design \n \n" +
        "Adobe Photoshop: Usage Examples"
        


    },
]