import { React, useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Masonry from "react-masonry-css";
import { magazineConfig } from "./magazine-config";
import "./Magazine.scss";
import Vimeo from "@u-wave/react-vimeo";
import { Link } from "react-router-dom";

const Magazine = (props) => {

  const [codeHeroLoaded, setCodeHeroLoaded] = useState(false);
  const [codeLabelLoaded, setCodeLabelLoaded] = useState(false);
  const [codeChapterLoaded, setCodeChapterLoaded] = useState(false);
  const [codeLogoLoaded, setCodeLogoLoaded] = useState(false);

  const handleCodeHero = () => {
    setCodeHeroLoaded(true);
  }

  const handleLabelHero = () => {
    setCodeLabelLoaded(true);
  }

  const handleCodeChapter = () => {
    setCodeChapterLoaded(true);
  }

  const handleCodeLogo = () => {
    setCodeLogoLoaded(true);
  }




  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
    <div id="page-wrapper">
      <Sidebar />

      
      <div className="sneak-content">
        <div className="video-wrapper">
          <div className="big-video-element">
          {!codeHeroLoaded && <div className="skeleton"></div>}
            <Vimeo
              video="711247084"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleCodeHero}
              quality="1080p"
              className="vimeo-player"
            ></Vimeo>
          </div>
        </div>

        <div className="video-wrapper">
          <div className="two-images">
            <img src="/assets/codeStill01.jpg" alt="spaghetti" />
            <div className="video-element">
            {!codeLabelLoaded && <div className="skeleton"></div>}
              <Vimeo
                video="710845186"
                width={"100%"}
                autoplays
                loop
                background
                responsive
                onPlay={handleLabelHero}
                quality="1080p"
                className="vimeo-player"
              ></Vimeo>
            </div>
          </div>
        </div>

        <div className="video-wrapper">
          <div className="big-video-element">
          {!codeChapterLoaded && <div className="skeleton"></div>}
            <Vimeo
              video="710880236"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleCodeChapter}
              quality="1080p"
              className="vimeo-player"
            ></Vimeo>
          </div>
        </div>

        <div className="video-wrapper">
          <div className="big-video-element">
          {!codeLogoLoaded && <div className="skeleton"></div>}
            <Vimeo
              video="711180697"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleCodeLogo}
              quality="1080p"
              className="vimeo-player"
            ></Vimeo>
          </div>
        </div>

        <div className="magazine-project-information-container">
          <div className="item-desc">
            <h2>{magazineConfig[0].title}</h2>
            <p>{magazineConfig[0].content}</p>
            <br />
            <a target="_blank" href="https://www.rwu.de/code">
              <p className="external-link">Visit the CODE Magazine Website</p>
            </a>
          </div>

          <div className="item-info">
            <h2>{magazineConfig[1].title}</h2>
            <p>{magazineConfig[1].content}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Magazine;
