export const brandConfig = [
    {
        id: 1,
        title: "Project Description",
        content: "A young studio that creates striking, modular design concepts. \n \n9INE 7EVEN is loud and bold - unconventional. The vibe of this brand should directly convey that it is something new. Provocative but precise, rebellious but systematic, urban but real. This concept should also be reflected in the typogram. Accurate through the alignment of the word elements, provocative by replacing letters with numbers. This contrast between identity and professionalism is underlined by the choice of font, Helvetica."

    },

    {
        id: 2,
        title: "Detailed Information",
        content: "Creator: Niklas Thielen \n \n" +

        "Software Usage \n" + "-- \n" +
        "Adobe Illustrator: Sketching and Conception, Font Manipulation \n \n" +
        "Adobe InDesign: Font Selection, Kerning, Layout & Presentation \n \n" +
        "Adobe After Effects: Motion Design \n \n" +
        "Adobe Photoshop: Usage Examples \n" + "-- \n" +
        
        "The video footage used in this project is not recorded or edited by myself. It's only used to transport the vibe of the brand."


    },

    
]