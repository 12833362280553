import { React, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Masonry from "react-masonry-css";
import { artConfig } from "./art-config";
import "./Art.scss";
import Vimeo from "@u-wave/react-vimeo";

const SneakPeak = (props) => {
  useEffect(() => {}, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div id="page-wrapper">
      <Sidebar /> 
      
      <div className="sneak-content">

      <div className="video-wrapper">
        <div className="big-video-element">
        <Vimeo
              video="701641940"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              className="vimeo-player"
            ></Vimeo>
        </div>
      </div>


      <div className="video-wrapper">
        <div className="two-images">  
          <img src="/assets/printSign.jpg" alt="spaghetti" />
            <div className="video-element">
              <Vimeo
              video="701644700"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              className="vimeo-player"
              ></Vimeo>
            </div>
          </div>
      </div>

      <div className="big-img">
          <img src="/assets/printBanner.jpg" alt="spaghetti" />
      </div>


      <div className="video-wrapper">
        <div className="big-video-element">
        <Vimeo
              video="701645564"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              className="vimeo-player"
            ></Vimeo>
        </div>
      </div>
    
      <div className="project-information-container">
      
      <div className="item-desc">
        <h2>{artConfig[0].title}</h2>
        <p>{artConfig[0].content}</p>
      </div>

      <div className="item-info">
        <h2>{artConfig[1].title}</h2>
        <p>{artConfig[1].content}</p>
      </div>

      </div>

        
      </div>
    </div>
  );
};

export default SneakPeak;
