export const teaserConfig = [
    {
        id: 1,
        title: "Project Description",
        content: "A Project to push social media presence and motivate to apply. \n \nWhat exactly lies behind the word media design? This teaser was produced to give prospective students an answer to this question. Because high school students are increasingly using social media to find potential universities, we took a new approach: short-time entertainment with Instagram content. High energy - Dynamic cuts - Striking typography. A successful project that saw the rate of applicants increase.\n \nTwo aspects we wanted to get across with this clip: The content of our course, broken down to a few words and the familiar community that forms during our studies. We all know each other and this should also be communicated to prospective students.\n \nIn order to provide an even deeper insight, we have produced a recap after the teaser was so well received. Here we describe exactly how the respective process steps were carried out. The Idea & Organisation - The Shooting Day - Post-Production - Motion Design."

    },

    {
        id: 2,
        title: "Detailed Information",
        content: "Creator \n" + "-- \n" +
        "Stephan Wedekind \n" +
        "Elias Engelhardt \n" +
        "Maxi Schellhaase \n" +
        "Niklas Thielen \n \n" +

        "Software Usage \n" + "-- \n" +
        "Adobe PremierePro: Cut, Color Grading, Post-Production \n \n" +
        "Adobe After Effects: Motion Design \n \n" +
        "Adobe Audition: SoundFX, Voice Over Preparation \n \n"
        


    },
]