import { React, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Masonry from "react-masonry-css";
import { sneakImagesConfig } from "./apart-config";
import "./Apart.scss";
import Vimeo from "@u-wave/react-vimeo";

const SneakPeak = (props) => {
  useEffect(() => {}, []);

  return (
    <div id="page-wrapper">
      <Sidebar />
      
      <div className="sneak-content">

      <div className="video-wrapper">
        <div className="big-video-element">
        <Vimeo
              video="695636596"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              className="vimeo-player"
            ></Vimeo>
        </div>
      </div>


      <div className="video-wrapper">
        <div className="two-images">  
          
          <img src="/assets/ApartScreenMockupHochkant.jpg" alt="spaghetti" />
          <div className="video-element">
            <Vimeo
            video="701577078"
            width={"100%"}
            autoplay
            loop
            background
            responsive
            className="vimeo-player"
            ></Vimeo>
          </div>
        </div>
      </div>



      <div className="video-wrapper">
        <div className="big-video-element">
        <Vimeo
              video="701590969"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              className="vimeo-player"
            ></Vimeo>
        </div>
      </div>


        
      </div>
    </div>
  );
};

export default SneakPeak;
