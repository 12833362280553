import { React, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Masonry from "react-masonry-css";
import { teaserConfig } from "./teaser-config";
import "./Teaser.scss";
import Vimeo from "@u-wave/react-vimeo";

const Teaser = (props) => {
  useEffect(() => {}, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div id="page-wrapper">
      <Sidebar />
      
      <div className="sneak-content">

     
      <div className="video-wrapper">
        <div className="big-video-element">
        <Vimeo
              video="709493689"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              className="vimeo-player"
            ></Vimeo>
        </div>
      </div>



      <div className="video-wrapper">
      
          
          <div className="video-element">
              <Vimeo
              video="706522778"
              
              width={"100%"}
              autoplay
              loop
              background
              responsive
              className="vimeo-player"
              ></Vimeo>
            </div>
        
            <div className="video-element">
              <Vimeo
              video="706462832"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              className="vimeo-player"
              ></Vimeo>
            </div>  
       

      </div>


      <div className="video-wrapper">
        <div className="big-video-element">
        <Vimeo
              video="706470828"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              className="vimeo-player"
            ></Vimeo>
        </div>
      </div>


      <div className="video-wrapper">
        <div className="two-images">  
        <div className="video-element">
            <Vimeo
            video="706591740"
            width={"100%"}
            autoplay
            loop
            background
            responsive
            className="vimeo-player"
            ></Vimeo>
          </div>
          <img src="/assets/teaserStill01.jpg" alt="spaghetti" />
        </div>
      </div>

      <div className="project-information-container">
      
      <div className="item-desc">
        <h2>{teaserConfig[0].title}</h2>
        <p>{teaserConfig[0].content}</p>
        <br/>
        <div className="link-group">
          <a target="_blank" href="https://www.instagram.com/tv/CNSdodSKGpm/?hl=de">
            <p className="external-link">Watch the Teaser on Instagram</p>
          </a>
          <a target="_blank" href="https://www.instagram.com/tv/CO20uYHKdto/">
            <p className="external-link">or the Recap</p>
          </a>
        </div>
      </div>

      <div className="item-info">
        <h2>{teaserConfig[1].title}</h2>
        <p>{teaserConfig[1].content}</p>
      </div>

      </div>
      
        
      </div>
    </div>
  );
};

export default Teaser;
