import { React, useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Masonry from "react-masonry-css";
import { linseConfig } from "./linse-config";
import "./Linse.scss";
import Vimeo from "@u-wave/react-vimeo";

const Linse = (props) => {
  useEffect(() => {}, []);

  const [heroLoaded, setHeroLoaded] = useState(false);
  const [signLoaded, setSignLoaded] = useState(false);
  const [blogpostLoaded, setBlogpostLoaded] = useState(false);
  const [colorsLoaded, setColorsLoaded] = useState(false);
  const [instaLoaded, setInstaLoaded] = useState(false);
  const [heroesLoaded, setHeroesLoaded] = useState(false);


  const handleHeroLoaded = () => {
    setHeroLoaded(true);
  }

  const handleSignLoaded = () => {
    setSignLoaded(true);
  }

  const handleBlogpostLoaded = () => {
    setBlogpostLoaded(true);
  }

  const handleColorsLoaded = () => {
    setColorsLoaded(true);
  }

  const handleInstaLoaded = () => {
    setInstaLoaded(true);
  }

  const handleHeroesLoaded = () => {
    setHeroesLoaded(true);
  }

  


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div id="page-wrapper">
      <Sidebar />
      
      <div className="sneak-content">

      <div className="video-wrapper">
          <div className="big-video-element">
          {!heroesLoaded && <div className="skeleton"></div>}
            <Vimeo
              video="870579716"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleHeroLoaded}
              quality="1080p"
              className="vimeo-player"
            ></Vimeo>
          </div>
        </div>

      <div className="video-wrapper">
        <div className="two-images">  
            <div className="video-element">
            {!signLoaded && <div className="skeleton"></div>}
              <Vimeo
              video="866365422"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleSignLoaded}
              quality="1080p"
              className="vimeo-player"
              ></Vimeo>
            </div>
            <img src="/assets/linse/2_2_bildmarke.jpg" alt="spaghetti" />
          </div>
      </div>

      {/* <div className="big-img">
      <img src="/assets/platzhalterGross.jpg" alt="spaghetti" />
      </div> */}

      <div className="video-wrapper">
          
          <div className="video-element">
          {!blogpostLoaded && <div className="skeleton"></div>}
              <Vimeo
              video="866802215"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleBlogpostLoaded}
              quality="1080p"
              className="vimeo-player"
              ></Vimeo>
            </div>
        
            <div className="video-element">
            {!colorsLoaded && <div className="skeleton"></div>}
              <Vimeo
              video="866806096"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleColorsLoaded}
              quality="1080p"
              className="vimeo-player"
              ></Vimeo>
            </div>  
        </div>

        <div className="big-img">
      <img src="/assets/linse/5_businesscard.jpg" alt="spaghetti" />
      </div>
      
      <div className="video-wrapper">
        <div className="two-images">  
        
            <div className="video-element">
            {!instaLoaded && <div className="skeleton"></div>}
              <Vimeo
              video="866802268"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleInstaLoaded}
              quality="1080p"
              className="vimeo-player"
              ></Vimeo>
            </div>
            <img src="/assets/linse/6_1_typo.jpg" alt="spaghetti" />
          </div>
      </div>

      <div className="video-wrapper">
          <div className="big-video-element">
          {!heroesLoaded && <div className="skeleton"></div>}
            <Vimeo
              video="866802297"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleHeroesLoaded}
              quality="1080p"
              className="vimeo-player"
            ></Vimeo>
          </div>
        </div>


        <div className="linse-project-information-container">
          <div className="item-desc">
            <h2>{linseConfig[0].title}</h2>
            <p>{linseConfig[0].content}</p>
            <br />
            <a target="_blank" href="https://www.kulturzentrum-linse.de">
              <p className="external-link">Visit the LINSE Website</p>
            </a>
          </div>

          <div className="item-info">
            <h2>{linseConfig[1].title}</h2>
            <p>{linseConfig[1].content}</p>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Linse;
