export const shortfilmConfig = [
    {
        id: 1,
        title: "Project Description",
        content: "A Shortfilm to raise awarness of the clothing industry and ultrafast fashion. \n \nIs the T-shirt you are wearing sustainably produced? Do you know where? And can you trust your sources? Just recently, even Fynn Kliemann was exposed as a fraud, with the mask deals he made. At a time when trends like 'One day - One Outfit' are becoming public, the fast and ultrafast fashion industry is losing a lot of transparency. Yet awareness and 'better' consumption are popular topics among the younger generation.\n \nIn total abstraction, this short film shows the white, 'pure' t-shirts we are presented with. But the intransparency of the companies is astonishing. Almost anything that says fair production on it, yet has suffering and disregard for human rights in it. This short film aims to reduce this gap between the clear, bright, almost sterile and the fast, hard and dark, that is still carriying harm into the world."

    },

    {
        id: 2,
        title: "Detailed Information",
        content: "Creator \n" + "-- \n" +
        "Stephan Wedekind \n" +
        "Maxi Schellhaase \n" +
        "Kilian Bendel \n" +
        "Niklas Thielen \n \n" +
        "Software Usage \n" + "-- \n" +
        "Adobe PremierePro: Cut, Color Grading, Post-Production \n \n" +
        "Adobe Audition: SoundFX Preparation \n \n" +
        "Adobe Lightroom: BTS Editing \n \n" +
        "Adobe After Effects: Motion Design \n \n"
        


    },
]