import { React, useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Masonry from "react-masonry-css";
import { letoConfig } from "./leto-config";
import "./Leto.scss";
import Vimeo from "@u-wave/react-vimeo";
import { Link } from "react-router-dom";

const Leto = (props) => {

  const [letoHeroAnimLoaded, setLetoHeroAnimLoaded] = useState(false);
  const [letoLogoLoaded, setLetoLogoLoaded] = useState(false);
  const [letoLoginLoaded, setLetoLoginLoaded] = useState(false);
  const [letoIconLoaded, setLetoIconLoaded] = useState(false);
  const [letoTitleLoaded, setLetoTitleLoaded] = useState(false);
  const [letoAvatarLoaded, setLetoAvatarLoaded] = useState(false);
  const [letoUiElements, setLetoUiElements] = useState(false);
  const [letoTeacherLoaded, setLetoTeacherLoaded] = useState(false);

  const handleLetoHeroAnim = () => {
    setLetoHeroAnimLoaded(true);
  }
  const handleLetoLogo = () => {
    setLetoLogoLoaded(true);
  }
  const handleLetoLogin = () => {
    setLetoLoginLoaded(true);
  }

const handleLetoIcon = () => {
  setLetoIconLoaded(true);
}
const handleLetoTitle = () => {
  setLetoTitleLoaded(true);
}
const handleLetoAvatar = () => {
  setLetoAvatarLoaded(true);
}
const handleLetoUiElements = () => {
  setLetoUiElements(true);
}
const handleLetoTeacher = () => {
  setLetoTeacherLoaded(true);
}

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="page-wrapper">
      <Sidebar />

      
      <div className="sneak-content">
        
      <div className="video-wrapper">

          <div className="big-video-element">
          {!letoHeroAnimLoaded && <div className="skeleton"></div>}
            <Vimeo
              video="866686413"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleLetoHeroAnim}
              quality="1080p"
              className="vimeo-player"
            ></Vimeo>
          </div>
        </div>

        <div className="video-wrapper">
          
          <div className="video-element">
          {!letoLogoLoaded && <div className="skeleton"></div>}
              <Vimeo
              video="867144279"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleLetoLogo}
              quality="1080p"
              className="vimeo-player"
              ></Vimeo>
            </div>
        
            <div className="video-element">
            {!letoLoginLoaded && <div className="skeleton"></div>}
              <Vimeo
              video="867152532"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleLetoLogin}
              quality="1080p"
              className="vimeo-player"
              ></Vimeo>
            </div>  
        </div>

        <div className="big-img">
          <img src="/assets/leto/leto_3_ipad.jpg" alt="spaghetti" />
      </div>


      <div className="video-wrapper">
      
          <div className="video-element">
          {!letoIconLoaded && <div className="skeleton"></div>}
              <Vimeo
              video="867138585"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleLetoIcon}
              quality="1080p"
              className="vimeo-player"
              ></Vimeo>
            </div>
        
            <div className="video-element">
            {!letoTitleLoaded && <div className="skeleton"></div>}
              <Vimeo
              video="867142340"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleLetoTitle}
              quality="1080p"
              className="vimeo-player"
              ></Vimeo>
            </div>  
        </div>

        <div className="big-img">
          <img src="/assets/leto/leto_5_klassenpokal.jpg" alt="spaghetti" />
      </div>


        <div className="video-wrapper">
        <div className="two-images">  
          
            <div className="video-element">
            {!letoAvatarLoaded && <div className="skeleton"></div>}
              <Vimeo
              video="867471321"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleLetoAvatar}
              quality="1080p"
              className="vimeo-player"
              ></Vimeo>
            </div>
            <img src="/assets/leto/leto_6_2_typo.jpg" alt="spaghetti" />
          </div>
      </div>

      <div className="video-wrapper">
          <div className="big-video-element">
          {!letoUiElements && <div className="skeleton"></div>}
            <Vimeo
              video="887634170"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleLetoUiElements}
              quality="1080p"
              className="vimeo-player"
            ></Vimeo>
          </div>
        </div>

      <div className="video-wrapper">
          <div className="big-video-element">
          {!letoTeacherLoaded && <div className="skeleton"></div>}
            <Vimeo
              video="867116334"
              width={"100%"}
              autoplay
              loop
              background
              responsive
              onPlay={handleLetoTeacher}
              quality="1080p"
              className="vimeo-player"
            ></Vimeo>
          </div>
        </div>

        <div className="leto-project-information-container">
          <div className="item-desc">
            <h2>{letoConfig[0].title}</h2>
            <p>{letoConfig[0].content}</p>
            <br />
            <a target="_blank" href="https://vimeo.com/739670056?share=copy">
              <p className="external-link">Watch the whole Teaser</p>
            </a>
          </div>

          <div className="item-info">
            <h2>{letoConfig[1].title}</h2>
            <p>{letoConfig[1].content}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leto;
