export const linseConfig = [
    {
        id: 1,
        title: "Project Description",
        content: "The Linse is an established institution in Weingarten. In addition to a small art house cinema with gastronomy, the Linse regularly hosts live cultural events. To adapt the visual appearance of the Linse to today's times, we developed a new logo with strong colors and also completely revised the web presence. A small special feature are the business cards, which have received an individual greeting on the front. This is to underline the diversity and speciality of the lens."

    },

    {
        id: 2,
        title: "Detailed Information",
        content: "Creator \n" + "-- \n" +
        "Stephan Wedekind \n" +
        "Simon Schlecker \n" +
        "Niklas Thielen \n \n" +
        
        "Software Usage \n" + "-- \n" +
        "Adobe Illustrator: Ideation, Visual Concept, Assets \n \n" +
        "Adobe InDesign: Editorial Design, Layout Creation \n \n" +
        "Adobe After Effects: Motion Design \n \n" +
        "Adobe After Xd: Website Prototyp \n \n" +
        "Adobe Photoshop: Usage Examples"
        


    },
]