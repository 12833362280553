import { React, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { sidebarConfig } from "./sidebar-config";
import "./Sidebar.scss";
import { ReactComponent as Typogramm } from "../../../src/logo_typogramm.svg";

const Sidebar = (props) => {
  const isActive = (url) => {
    if (window.location.pathname === url) {
      return "active-dot";
    }

    return "";
  };

  return (
    <div>
      <div className="sidebar-wrapper">
        <div className="corner-container">
          <Link to="/" className="logo-container">
            <Typogramm className="Nine-Seven" />
          </Link>
          <h5 className="info">
            A german Designer <br />
            Based from Mainz
          </h5>
        </div>

        {sidebarConfig.map((config, i) => (
          <Link to={config.url} key={i}>
            <div
              className={`tile ${
                i === 0 && window.innerWidth >= 800 ? "hidden" : ""
              }`}
            >
              <div className="title-row">
                <p>{config.category}</p>
                <p>Show more</p>
              </div>
              <div className="content">
                {config.image && <img src={config.image} alt={config.title} />}
                <div>
                  <h2>{config.title}</h2>
                  <p>{config.description}</p>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>

      <div className="mobile-bottom-bar">
        <div className="overlay-bottom-bar"></div>
        <div className="icons-wrapper">
          {sidebarConfig.map((config, i) => (
            <Link to={config.url} key={i}>
              {config.image && (
                <div className="logo-container">
                  <img
                    className="logo-bottom-bar"
                    src={config.image}
                    alt={config.title}
                  />
                  <div className={isActive(config.url)}></div>
                </div>
              )}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
