export const artConfig = [
    {
        id: 1,
        title: "Project Description",
        content: "A Poster Concept for the expressionist Lee Krasner. \n \nIn the module Graphic Design, I conceived the 9INE 7EVEN brand identity and applied it to sample works. An art exhibition is ideal for such an example, because it should attract attention, but you still have a lot of freedom in the design. So a construction kit was created that works in many different combinations. \n \nEspecially expressionism puts a lot of emphasis on dynamics. To underline this dynamic I have created a modular concept. So with only a handful of artworks selected for marketing, countless designs can be produced. The loud font and saturated background colors make the poster look very attractive. And when you see several posters with the same content, the design language differs and you are encouraged to discover more posters or even visit the exhibition."

    },

    {
        id: 2,
        title: "Detailed Information",
        content: "Creator: Niklas Thielen \n \n" +

        "Software Usage \n" + "-- \n" +
        "Adobe InDesign: Editorial Design, Layout Creation \n \n" +
        "Adobe Illustrator: Asset Creation and Preparation \n \n" +
        "Adobe After Effects: Motion Design \n \n" +
        "Adobe Photoshop: Usage Examples"
        


    },
]